import { FC } from "react";
import { StyledMenu, StyledMenuItem } from "./styles";
import { Box } from "@mui/material";
import { MenuProps as MuiMenuProps } from "@mui/material/Menu";

const Menu: FC<MenuProps> = ({
  anchorEl,
  onClose,
  menuItems,
  disableTouchRippleItem,
  noDividerBetweenItems,
  ...muiProps
}) => {
  return (
    <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} {...muiProps}>
      {menuItems.map(
        (menuItem, index) =>
          !menuItem.hidden && (
            <StyledMenuItem
              key={index}
              disabled={menuItem.disabled}
              onClick={menuItem.onClick}
              isLastItem={index === menuItems.length - 1}
              customColor={menuItem.color}
              disableTouchRipple={disableTouchRippleItem}
              sx={{
                display: "block",
                "&:hover": { color: menuItem.color || undefined },
                padding: menuItem.content ? "0px" : undefined,
                border: noDividerBetweenItems ? "none" : undefined,
              }}
            >
              {(menuItem.icon || menuItem.message) && (
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  {menuItem.icon} {menuItem.message}
                </Box>
              )}
              {menuItem.content && <Box>{menuItem.content}</Box>}
            </StyledMenuItem>
          )
      )}
    </StyledMenu>
  );
};

export default Menu;

export interface MenuItemInfo {
  onClick: (arg?: any) => void;
  message?: string;
  hidden?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  color?: string;
  content?: JSX.Element;
}

interface MenuProps extends Omit<MuiMenuProps, "open"> {
  anchorEl: null | HTMLElement;
  onClose: (arg?: any) => void;
  menuItems: MenuItemInfo[];
  disableTouchRippleItem?: boolean;
  noDividerBetweenItems?: boolean;
}
