import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { DocumentDetails } from "@/types/contracts";

const addContractDocumentEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/documents/upload`,
      method: "POST",
      body: request,
    }),
    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.contractId }],
  });
};

export default addContractDocumentEndPoint;

type Request = DocumentDetails;
