import { Box, Button, ButtonBase, styled } from "@mui/material";

export const StyledButtonBase = styled(ButtonBase)(({ theme, disabled }) => ({
  width: "100%",
  height: 40,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
  background: theme.palette.background.default,
  border: `solid 1px`,
  borderColor: disabled
    ? theme.palette.text.disabled
    : theme.palette.mode === "dark"
    ? `${theme.palette.neutral.main}DD`
    : `${theme.palette.neutral.main}5A`,
  color: disabled ? theme.palette.text.disabled : "inherit",
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : theme.palette.customGrey.dark,
  padding: "21px 24px",
  borderBottom: "1px solid",
  borderColor: `${theme.palette.border.main}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "sticky",
  top: 0,
  zIndex: 2,
}));

export const DrawerBottom = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  minWidth: "32vw",
  maxWidth: "32vw",
  height: "10vh",
  padding: "30px",
  display: "flex",
  background: theme.palette.background.default,
  justifyContent: "flex-end",
}));

export const StyledButton = styled(Button)(() => ({
  marginLeft: "8px",
  boxShadow: "none",
  borderRadius: 0,
}));
