import { useEffect, useState } from "react";

const useDebounce = <T>(search: T): T => {
  const [debouncedSearch, setDebouncedSearch] = useState<T>(search);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  return debouncedSearch;
};

export default useDebounce;
