import { FormikErrors } from "formik";
import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import useDirection from "@/hooks/useDirection";
import { StyledButton } from "./styles";

type SingleButton = {
  textButton: string | JSX.Element;
  buttonProps: ButtonProps;
  buttonState: "primary" | "secondary";
  isLoading: boolean;
};
type InputWrapperProps = {
  buttons: (SingleButton | undefined | JSX.Element)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FormikErrors<any>;
};

const InputWrapper = ({ buttons }: InputWrapperProps) => {
  const currentDirection = useDirection();

  const singleButtons: SingleButton[] = [];
  const otherElements: JSX.Element[] = [];

  buttons.forEach(button => {
    if (button && typeof button === "object" && "buttonProps" in button) {
      singleButtons.push(button as SingleButton);
    } else if (button && typeof button === "object") {
      otherElements.push(button as JSX.Element);
    }
  });

  return (
    <div
      style={{
        display: "flex",
        marginTop: "-1rem",
        [currentDirection === "rtl" ? "marginRight" : "marginLeft"]: "auto",
      }}
    >
      {otherElements}
      {singleButtons.map(({ textButton, buttonProps, isLoading }) => (
        <StyledButton
          type="submit"
          disabled={isLoading}
          {...buttonProps}
          key={`button-${
            typeof textButton === "string" ? textButton : Math.round(Math.random() * 1000)
          }`}
          href=""
        >
          {isLoading ? <LoadingButton loading disabled={false} /> : textButton}
        </StyledButton>
      ))}
    </div>
  );
};

export default InputWrapper;
