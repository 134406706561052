import { Chip as MuiChip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Name = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "12px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const Description = styled(Typography)(() => ({
  fontSize: "10px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  wordWrap: "break-word",
}));

export const CategoryChip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: "#C5AEFF",
  color: theme.palette.neutral.main,
  fontSize: "10px",
  height: "20px",
}));

export const ContractsChip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  fontSize: "10px",
  height: "20px",
}));
