import { ApiEndpointBuilder } from "@/services/api/types";
import { ContractEnterpriseDetails } from "@/types/contracts";

const getContractEnterpriseById = (builder: ApiEndpointBuilder) => {
  return builder.query<ContractEnterpriseDetails, { id: string }>({
    query: request => ({
      url: `/contracts/enterprisecontracts/${request.id}`,
      method: "GET",
    }),
    providesTags: (_result, _error, arg) => [{ type: "contract", id: arg.id }],
  });
};
export default getContractEnterpriseById;
