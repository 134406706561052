import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { DataResponse } from "@/types/reponse-data.ts";
import { TemplateCategoryDetails } from "@/types/contracts";
import {TemplateSearchRequest} from "@/store/contracts/endpoints/templates-paragraphs/search-contract-template.ts";

const searchInfiniteTemplatesEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<TemplateCategoryDetails>, TemplateSearchRequest>({
    query: request => {
      return {
        url: `/contracts/templates/search`,
        method: "POST",
        body: request,
      };
    },
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },

    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return { ...draft };
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    // merge current cache with new data
    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;
      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },
  });
};

export default searchInfiniteTemplatesEndPoint;
