import { defineMessages } from "react-intl";

const scope = "filterPageWrapper";

const messages = defineMessages({
  clearFilter: {
    id: `${scope}.clearFilter`,
    defaultMessage: "Effacer les filtres ",
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: "Filtrer",
  },
});

export default messages;
