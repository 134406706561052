import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { ContractProjectDetails } from "@/types/contracts";
import { contractsApi } from "@/store/contracts/endpoints";

const updateProjectEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: ({ id, contractCategory, ...request }) => ({
      url: `/contracts/projects/${id}`,
      method: "PUT",
      body: {
        id,
        ...request,
        contractCategoryId: contractCategory?.id,
      },
    }),

    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      const patchFetchResult = dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        contractsApi.util.updateQueryData("searchInfiniteContractProjects", {}, draftPosts => {
          return {
            ...draftPosts,
            data: [
              ...(draftPosts?.data ?? []).map(x =>
                x.id === args?.id
                  ? {
                      ...args,
                    }
                  : x
              ),
            ],
          };
        })
      );

      try {
        await queryFulfilled;
      } catch (err) {
        // Undo the cache update if an error occurs
        console.error(err);

        patchFetchResult.undo();
      }
    },
  });
};

export default updateProjectEndPoint;

type Request = Omit<ContractProjectDetails, "enterpriseContracts" | "receiverEnterprise">;
