import { Box, Stack, styled, SxProps } from "@mui/material";

export const LayoutBoxHeader = styled(Box)({
  ["&"]: {
    paddingBottom: "15px",
  },
});

export const Container = styled(Stack)<{ sx: SxProps }>(({ theme, sx }) => ({
  ["&"]: {
    border: `1px solid `,
    borderColor: theme.palette.border.main,
    paddingTop: "20px",
    paddingBottom: "15px",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "15px",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.default,
    ...sx,
  },
}));
