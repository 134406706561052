import { FC, FocusEventHandler } from "react";
import { Select, SxProps, SelectChangeEvent, MenuItem, FormHelperText } from "@mui/material";
import { FormControlCategories, InputLabelLongWord } from "./styles";
import { classesSelectCategories } from "./styles";
import { ContentCategory } from "@/types/content-category";
import { useIntl } from "react-intl";
import { LOCALES } from "@/i18n/locales";

const SelectOptionFilter: FC<Props> = props => {
  const {
    label,
    options,
    fullWidth,
    onChange,
    onBlur,
    value, 
    error,
    textError,
    name,
    disabled,
    optionLabel,
    disableNoneValue = false,
    sx = {},
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };
  const { locale } = useIntl();

  return (
    <FormControlCategories
      error={error}
      className={
        fullWidth
          ? `${classesSelectCategories.fullWidth}`
          : `${classesSelectCategories.widthFilter}`
      }
    >
      <InputLabelLongWord id="category-label" disabled={disabled}>
        {label}
      </InputLabelLongWord>
      <Select
        disabled={disabled}
        labelId="category-label"
        id="category-input"
        value={value}
        onChange={(e: any) => handleChange(e)}
        onBlur={onBlur}
        label={label}
        name={name}
        size="small"
        className="Select"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        sx={sx}
      >
        {!disableNoneValue && (
          <MenuItem value="">
            <em>{locale === LOCALES.ENGLISH ? "None" : "Tous"}</em>
          </MenuItem>
        )}

        {options &&
          options.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {optionLabel ? option[optionLabel] : option.title}
            </MenuItem>
          ))}
      </Select>
      {error && <FormHelperText>{textError}</FormHelperText>}
    </FormControlCategories>
  );
};

interface Props {
  label: string;
  options?: ContentCategory[] | null | any;
  fullWidth?: boolean;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  textError?: string;
  name?: string;
  disabled?: boolean;
  optionLabel?: string;
  disableNoneValue?: boolean;
  sx?: SxProps;
}

export default SelectOptionFilter;
