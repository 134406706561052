import { defineMessages } from "react-intl";

export const scope = "nodata";

export default defineMessages({
  nodata: {
    id: `${scope}.nodata`,
    defaultMessage: "Pas de résultats trouvés",
  },
});
