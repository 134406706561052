import theme from "@/theme/theme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, SxProps, Theme } from "@mui/material";
import { FC } from "react";

const ButtonVaried: FC<Props> = props => {
  const {
    variantType,
    textContent,
    onAction,
    buttonType,
    disabled = false,
    color = "primary",
    size = "medium",
    endIcon = null,
    startIcon = null,
    sxCustom = {},
  } = props;

  // "primary" ||"error" || "secondary"
  return color === "primary" || color === "error" || color === "secondary" ? (
    <Button
      type={buttonType}
      onClick={onAction}
      variant={variantType}
      color={color}
      size={size}
      disabled={disabled}
      startIcon={startIcon ? <FontAwesomeIcon icon={startIcon} /> : null}
      endIcon={endIcon ? <FontAwesomeIcon icon={endIcon} /> : null}
      sx={{
        whiteSpace: "nowrap", // Prevents text from wrapping
        overflow: "hidden", // Hides text that exceeds the button's width
        textOverflow: "ellipsis", // Displays an ellipsis (...) if the text overflows
        borderRadius: 0,
        boxShadow: "none",
        textTransform: "initial",
        ...sxCustom,
      }}
    >
      {textContent}
    </Button>
  ) : (
    <Button
      color={color}
      size={size}
      type={buttonType}
      onClick={onAction}
      variant={variantType}
      startIcon={startIcon ? <FontAwesomeIcon icon={startIcon} /> : null}
      endIcon={endIcon ? <FontAwesomeIcon icon={endIcon} /> : null}
      disabled={disabled}
      sx={{
        "&": {
          backgroundColor: theme.palette[color].light,
          color: theme.palette[color].main,
          fontWeight: 600,
          textTransform: "unset",
          borderRadius: "3px",
          boxShadow: "unset",
          fontSize: size == "medium" ? 14 : "",
          height: size == "medium" ? 40 : "",
          paddingX: size == "medium" ? "20px" : "",
        },
        "&:hover": {
          backgroundColor: theme.palette.grey["50"],
          boxShadow: "unset",
        },
        ...sxCustom,
      }}
    >
      {textContent}
    </Button>
  );
};

interface Props {
  variantType: "text" | "contained" | "outlined";
  textContent?: string;
  onAction?: (value: any) => void;
  buttonType?: "submit" | "reset" | "button";
  disabled?: boolean;
  color?: "primary" | "success" | "warning" | "error" | "secondary";
  size?: "large" | "medium" | "small";
  startIcon?: IconProp;
  endIcon?: IconProp;
  sxCustom?: SxProps<Theme>;
}
export default ButtonVaried;
