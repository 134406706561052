import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { DataResponse } from "@/types/reponse-data.ts";
import { SearchRequest } from "@/types/search-request.ts";
import { ContractCategory } from "@/types/contracts";

const searchContractCategoriesEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<ContractCategory>, SearchRequest>({
    query: request => ({
      url: "/contracts/categories/search",
      method: "POST",
      body: request,
    }),
  });
};

export default searchContractCategoriesEndpoint;
