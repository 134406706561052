import { api } from "@/services/api";
import searchContractCategoriesEndpoint from "./categories/search-contract-category.ts";
import searchInfiniteContractTemplatesEndPoint from "@/store/contracts/endpoints/templates-paragraphs/search-infinite-contract-template.ts";
import searchContractTemplatesEndPoint from "@/store/contracts/endpoints/templates-paragraphs/search-contract-template.ts";
import searchContractParagraphsEndpoint from "@/store/contracts/endpoints/templates-paragraphs/search-contract-paragraph.ts";
import searchInfiniteContractProjectsEndpoint from "@/store/contracts/endpoints/projects/search-infinite-contract-projects.ts";
import addContractProjectEndPoint from "@/store/contracts/endpoints/projects/add-contract-project.ts";
import deleteContractProjectEndPoint from "@/store/contracts/endpoints/projects/delete-contract-project.ts";
import updateContractProjectEndPoint from "@/store/contracts/endpoints/projects/update-contract-project.ts";
import searchInfiniteContractEnterprisesEndpoint from "@/store/contracts/endpoints/enterprises/search-infinite-contract-enterprises.ts";
import addContractEnterpriseEndPoint from "@/store/contracts/endpoints/enterprises/add-contract-enterprise.ts";
import deleteContractEnterpriseEndPoint from "@/store/contracts/endpoints/enterprises/delete-contract-enterprise.ts";
import updateContractEnterpriseEndPoint from "@/store/contracts/endpoints/enterprises/update-contract-enterprise.ts";
import getContractEnterpriseByIdEndPoint from "@/store/contracts/endpoints/enterprises/get-contract-enterprise.ts";
import addContractEnterpriseUserRoleEndpoint from "@/store/contracts/endpoints/users-roles/add-contract-users-roles.ts";
import addContractDocumentEndPoint from "@/store/contracts/endpoints/documents/add-contract-document.ts";
import deleteContractDocumentsEndPoint from "@/store/contracts/endpoints/documents/delete-contract-document.ts";
import addContractReminderEndPoint from "@/store/contracts/endpoints/reminders/add-contract-reminder.ts";
import deleteContractReminderEndPoint from "@/store/contracts/endpoints/reminders/delete-contract-reminder.ts";
import searchContractFilesTagsEndpoint from "@/store/contracts/endpoints/file-tags/search-contract-file-tags.ts";
import deleteContractUserRoleEndpoint from "@/store/contracts/endpoints/users-roles/delete-contract-user-role.ts";
import updateContractUserRoleEndPoint from "@/store/contracts/endpoints/users-roles/update-contract-user-role.ts";
import updateContractDocumentEndPoint from "@/store/contracts/endpoints/documents/update-contract-document.ts";
import updateContractReminderEndPoint from "@/store/contracts/endpoints/reminders/update-contract-reminder.ts";
import addContractTemplate from "@/store/contracts/endpoints/templates-paragraphs/add-contract-template.ts";
import deleteContractTemplate from "@/store/contracts/endpoints/templates-paragraphs/delete-contract-template.ts";

export const contractsApi = api.injectEndpoints({
  endpoints: build => ({
    addContractProject: addContractProjectEndPoint(build),
    deleteContractProject: deleteContractProjectEndPoint(build),
    updateContractProject: updateContractProjectEndPoint(build),
    searchInfiniteContractProjects: searchInfiniteContractProjectsEndpoint(build),

    addContractTemplate: addContractTemplate(build),
    deleteContractTemplate: deleteContractTemplate(build),
    searchContractCategories: searchContractCategoriesEndpoint(build),
    searchInfiniteContractTemplates: searchInfiniteContractTemplatesEndPoint(build),
    searchContractTemplates: searchContractTemplatesEndPoint(build),
    searchContractParagraphs: searchContractParagraphsEndpoint(build),

    addContractEnterprise: addContractEnterpriseEndPoint(build),
    deleteContractEnterprise: deleteContractEnterpriseEndPoint(build),
    updateContractEnterprise: updateContractEnterpriseEndPoint(build),
    getContractEnterpriseById: getContractEnterpriseByIdEndPoint(build),
    searchInfiniteContractEnterprises: searchInfiniteContractEnterprisesEndpoint(build),

    addContractEnterpriseUserRole: addContractEnterpriseUserRoleEndpoint(build),
    deleteContractEnterpriseUserRole: deleteContractUserRoleEndpoint(build),
    updateContractEnterpriseUserRole: updateContractUserRoleEndPoint(build),

    addContractDocument: addContractDocumentEndPoint(build),
    deleteContractDocument: deleteContractDocumentsEndPoint(build),
    updateContractDocument: updateContractDocumentEndPoint(build),

    addContractReminder: addContractReminderEndPoint(build),
    deleteContractReminder: deleteContractReminderEndPoint(build),
    updateContractReminder: updateContractReminderEndPoint(build),

    searchContractFilesTags: searchContractFilesTagsEndpoint(build),
  }),
});

export const {
  useGetContractEnterpriseByIdQuery,

  useAddContractTemplateMutation,
  useAddContractDocumentMutation,
  useAddContractReminderMutation,
  useAddContractProjectMutation,
  useAddContractEnterpriseMutation,
  useAddContractEnterpriseUserRoleMutation,

  useUpdateContractProjectMutation,
  useUpdateContractDocumentMutation,
  useUpdateContractEnterpriseMutation,
  useUpdateContractReminderMutation,
  useUpdateContractEnterpriseUserRoleMutation,

  useDeleteContractDocumentMutation,
  useDeleteContractTemplateMutation,
  useDeleteContractReminderMutation,
  useDeleteContractProjectMutation,
  useDeleteContractEnterpriseMutation,
  useDeleteContractEnterpriseUserRoleMutation,

  useSearchContractCategoriesQuery,
  useSearchContractTemplatesQuery,
  useSearchContractParagraphsQuery,

  useLazySearchContractFilesTagsQuery,
  useLazySearchInfiniteContractTemplatesQuery,
  useLazySearchInfiniteContractProjectsQuery,
  useLazySearchInfiniteContractEnterprisesQuery,
} = contractsApi;
