import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { TemplateDetails } from "@/types/contracts";

const addContractTemplateEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/templates`,
      method: "POST",
      body: request,
    }),
  });
};

export default addContractTemplateEndpoint;

type Request = Omit<TemplateDetails, "id">;
