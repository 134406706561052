import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { ContractReminder } from "@/types/contracts";

const addContractReminderEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/enterprisecontracts/reminder`,
      method: "POST",
      body: request,
    }),
    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.contractId }],
  });
};

export default addContractReminderEndPoint;

type Request = ContractReminder & { contractId: string };
