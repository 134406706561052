import useToggleModal from "@/hooks/useToggleModal";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  ButtonBase,
  Checkbox,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import messages from "../messages.ts";
import useDirection from "@/hooks/useDirection.ts";
import { DrawerBottom, DrawerHeader, StyledButton, StyledButtonBase } from "./styles.ts";

type DraweredSelectProps =
  | {
      title: string;
      subTitle?: string;
      options: { label: string; value: string }[];
      disabled?: boolean;
    } & (
      | {
          variant: "multi";
          defaultValue: string[];
          onChange: (value: string[]) => void;
        }
      | {
          variant: "single";
          onChange: (value: string) => void;
          defaultValue: string | undefined;
        }
    );
const DraweredSelect = ({
  variant,
  options,
  title,
  defaultValue,
  onChange,
  disabled,
}: DraweredSelectProps) => {
  const currentDirection = useDirection();
  const { isOpen, toggle, forceState } = useToggleModal();
  const [checkedBoxes, setCheckedBoxes] = useState<{ [key: string]: boolean }>({});
  const [selectedSingleOption, setSelectedSingleOption] = useState<string | undefined>();

  const onCheckBoxClick = (checkboxID: string) => {
    setCheckedBoxes(prev => ({ ...prev, [checkboxID]: !prev[checkboxID] }));
  };

  const { formatMessage: __ } = useIntl();

  useEffect(() => {
    if (variant === "multi") {
      setCheckedBoxes(
        options.reduce((prev: { [key: string]: boolean }, current) => {
          prev[current.value] = (defaultValue as string[]).includes(current.value);
          return prev;
        }, {})
      );
      setSelectedSingleOption(undefined);
    } else {
      setCheckedBoxes({});
      setSelectedSingleOption(defaultValue);
    }
    if (defaultValue) {
      const defaultValueLabel =
        variant === "single"
          ? options.find(option => option.value === defaultValue)?.label || title
          : defaultValue.length > 1
          ? `${options.find(option => option.value === defaultValue[0])?.label} +${
              defaultValue.length - 1
            }`
          : defaultValue.length === 0
          ? title
          : options.find(option => option.value === defaultValue[0])?.label;
      setFinalValueAsString(defaultValueLabel as string);
    } else {
      setFinalValueAsString(title);
    }
  }, [variant, defaultValue, title]);

  const onRadioGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSingleOption(e.target.value);
    variant === "single" && // TS only
      onChange(e.target.value);
    forceState(false);
    setFinalValueAsString(
      options.find(option => option.value === e.target.value)?.label ?? "Filtre"
    );
  };

  const parseValueAsString = () => {
    if (variant === "multi") {
      const filteredSelectedCheckboxes = Object.entries(checkedBoxes)
        .filter(checkbox => checkbox[1])
        .map(checkedBox => checkedBox[0]);

      const foundCheckBoxes = options
        .filter(option => filteredSelectedCheckboxes.includes(option.value))
        .map(checkbox => checkbox.label);

      setFinalValueAsString(
        foundCheckBoxes.length > 0
          ? foundCheckBoxes.length > 1
            ? `${foundCheckBoxes[0]} +${foundCheckBoxes.length - 1}`
            : foundCheckBoxes[0]
          : title
      );
    }
  };
  const [finalValueAsString, setFinalValueAsString] = useState<string>(title);

  const handleRadioGroupClick = (event: any) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    if (selectedSingleOption === value) {
      setSelectedSingleOption(undefined);
      variant === "single" && // TS only
        onChange("");
      forceState(false);
      setFinalValueAsString(title);
    }
  };

  return (
    <>
      <StyledButtonBase
        disabled={disabled}
        onClick={() => {
          toggle();
        }}
      >
        <Typography noWrap sx={{ fontWeight: finalValueAsString !== title ? 600 : undefined }}>
          {finalValueAsString}
        </Typography>
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{
            rotate: currentDirection === "rtl" ? "180deg" : "0deg",
          }}
        />
      </StyledButtonBase>
      <Drawer anchor="right" open={isOpen} onClose={toggle}>
        <Box sx={{ minWidth: "32vw", maxWidth: "32vw" }}>
          <DrawerHeader>
            <Box display="flex" flexDirection="column">
              <Typography variant={"h5"} color={"default"} fontWeight={400}>
                <b>{__(messages.filter)} </b>- {title}
              </Typography>
              <Typography variant="subtitle2" color="gray">
                {__(messages.shouldSelect)}
                {variant === "multi" ? __(messages.multiSelect) : __(messages.singleSelect)}
                {__(messages.nextElement)}
              </Typography>
            </Box>
            <ButtonBase
              onClick={() => {
                forceState(false);
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={18} />
            </ButtonBase>
          </DrawerHeader>
          <>
            {variant === "multi" ? (
              <Box sx={{ padding: 3, overflowY: "auto", marginBottom: "11vh" }}>
                {options.map(({ label, value }) => (
                  <CheckboxOption
                    {...{ label, value }}
                    checked={!!checkedBoxes?.[value as string]}
                    onClick={onCheckBoxClick}
                  />
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ padding: 3 }}
              >
                <RadioGroup
                  value={selectedSingleOption}
                  onChange={onRadioGroupChange}
                  onClick={handleRadioGroupClick}
                >
                  {options.map(({ label, value }) => (
                    <RadioOption {...{ label, value }} onClick={() => null} />
                  ))}
                </RadioGroup>
              </Box>
            )}
          </>
        </Box>
        {variant === "multi" && (
          <DrawerBottom>
            <StyledButton
              onClick={() => {
                const finalValue = Object.entries(checkedBoxes)
                  .filter(checkbox => checkbox[1])
                  .map(checkbox => checkbox[0]);

                onChange(finalValue);
                forceState(false);
                parseValueAsString();
              }}
            >
              {__(messages.applyFilter)}
            </StyledButton>
          </DrawerBottom>
        )}
      </Drawer>
    </>
  );
};

export default DraweredSelect;

type CheckboxOptionProps = {
  label: string;
  value: unknown;
  checked: boolean;
  onClick: (checkboxId: string) => void;
};
const CheckboxOption = ({ label, value, checked, onClick }: CheckboxOptionProps) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      onClick={() => onClick(value as string)}
      sx={{ cursor: "pointer" }}
    >
      <Checkbox
        checked={checked}
        onClick={event => {
          event.preventDefault();
        }}
      />
      <Typography>{label}</Typography>
    </Box>
  );
};

type RadioOptionProps = {
  label: string;
  value: string;
  onClick: () => void;
};
const RadioOption = ({ label, value }: RadioOptionProps) => {
  return <FormControlLabel value={value} control={<Radio />} label={label} />;
};
