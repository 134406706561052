import { Stack, Typography, TypographyVariant } from "@mui/material";
import { FC } from "react";
import { LayoutBoxHeader } from "./styles";
import ButtonVaried from "../button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  textTitle?: string;
  textSubTitle?: React.ReactNode;
  variant?: TypographyVariant | "inherit";
  addButton?: boolean;
  textButton?: string;

  addButtonFn?: () => void;
  addButtonStartIcon?: IconProp | undefined;
}

const BoxHeader: FC<Props> = props => {
  const {
    textTitle,
    textSubTitle,
    variant = "h3",
    addButton,
    textButton = "click",
    addButtonFn = () => {},
    addButtonStartIcon,
  } = props;
  return (
    <Stack
      display={"flex"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <LayoutBoxHeader>
        <Typography variant={variant} color="primary" sx={{ marginBottom: "10px" }}>
          {textTitle}
        </Typography>
        <Typography variant="h5">{textSubTitle}</Typography>
      </LayoutBoxHeader>
      {addButton && (
        <ButtonVaried
          textContent={textButton}
          variantType="contained"
          onAction={() => addButtonFn()}
          startIcon={addButtonStartIcon}
        />
      )}
    </Stack>
  );
};

export default BoxHeader;
