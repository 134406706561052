import { FC } from "react";
import { Typography, Box, Stack, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import noImageLight from "./nodata.svg";
import noImageDark from "./nodata-dark.svg";
import messages from "./messages";

const NoData: FC = () => {
  const { formatMessage: __ } = useIntl();
  const theme = useTheme();
  const imageSrc = theme.palette.mode === "dark" ? noImageDark : noImageLight;

  return (
    <Stack justifyContent="center" alignItems="center" sx={{ marginBottom: "3rem" }}>
      <Box>
        <img src={imageSrc} alt="No data" loading="lazy" style={{ width: "100px" }} />
      </Box>
      <Typography variant="h6">{__(messages.nodata)}</Typography>
    </Stack>
  );
};

export default NoData;
