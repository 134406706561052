import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { ContractEnterprise } from "@/types/contracts";

const addContractEnterpriseEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/enterprisecontracts`,
      method: "POST",
      body: request,
    }),
  });
};

export default addContractEnterpriseEndpoint;

export type Request = Omit<
  ContractEnterprise,
  | "contractProjectId"
  | "contractProject"
>;
