import React, { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Box, IconButton } from "@mui/material";
import { Types } from "@/components/filter-component";
import messages from "./messages";
import DataTable from "@/containers/data-table-material";
import {
  useDeleteContractTemplateMutation,
  useLazySearchInfiniteContractTemplatesQuery,
} from "@/store/contracts/endpoints";
import { CategoryChip, Description, Name } from "./style.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import Menu, { MenuItemInfo } from "@components/menu-items";
import useToggleModal from "@hooks/useToggleModal.tsx";
import ConfirmationModal from "@components/confirmation-modal";
import { TemplateCategoryDetails } from "@/types/contracts";
import toast from "react-hot-toast";

const MyTemplatesPage: FC = () => {
  const { formatMessage: __ } = useIntl();

  const { isOpen: isConfirmationModalOpen, toggle: toggleConfirmationModal } = useToggleModal();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [template, setTemplate] = useState<TemplateCategoryDetails | undefined>(undefined);

  const [deleteTemplate] = useDeleteContractTemplateMutation();

  const MenuItems: MenuItemInfo[] = [
    {
      onClick: () => handleDeleteClick(),
      icon: <FontAwesomeIcon icon={faTrash} />,
      message: __(messages.delete),
    },
  ];

  const handleDeleteClick = useCallback(() => {
    toggleConfirmationModal();
    handleClose();
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    template: TemplateCategoryDetails
  ) => {
    setTemplate(template);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteTemplate({
        id: template?.id as string,
      }).unwrap();
      toast.success(__(messages.successDeletingTemplate));
    } catch (error) {
      toast.error(__(messages.errorDeletingTemplate));
    } finally {
      setTemplate(undefined);
    }
  };

  return (
    <Box
      sx={{
        marginTop: "25px",
      }}
    >
      <DataTable
        columns={[
          {
            header: `${__(messages.name)}`,
            accessorKey: "name",
            Cell: ({ row }) => (
              <>
                <Name>{row.original.name}</Name>
                <Description>{row.original.description}</Description>
              </>
            ),
          },
          {
            header: `${__(messages.category)}`,
            accessorKey: "contractCategory.name",
            Cell: ({ cell }) => <CategoryChip label={cell.getValue() as string} size={"small"} />,
          },
          {
            header: `${__(messages.actions)}`,
            accessorKey: "actions",
            enableHiding: true,
            Cell: ({ row }) => {
              return (
                <IconButton onClick={e => handleClick(e, row.original)} size={"small"}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </IconButton>
              );
            },
          },
        ]}
        lazyQuery={useLazySearchInfiniteContractTemplatesQuery}
        configProps={{
          useDimensionsMargin: 90,
          defaultQueryValues: {
            advancedFilter: { logic: "and" },
            onlyMine: true,
            onlyRoot: false,
          },
        }}
        configFilters={{
          showColumnFilters: false,
          showActionsColumn: false,
        }}
        filters={[
          {
            type: Types.TEXT_FIELD,
            pattern: "advancedSearch.name",
            label: __(messages.search),
            style: { width: "275px" },
          },
        ]}
      />
      {anchorEl && <Menu anchorEl={anchorEl} onClose={() => handleClose()} menuItems={MenuItems} />}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          open={isConfirmationModalOpen}
          toggle={toggleConfirmationModal}
          message={__(messages.deleteTemplate)}
          action={() => handleDeleteConfirmation()}
        />
      )}
    </Box>
  );
};

export default MyTemplatesPage;
