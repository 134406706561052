import { SearchRequest } from "@/types/search-request";
import _ from "lodash";

/**
 * Maps a given key to a value in the provided SearchRequest object.
 *
 * @param key - The pattern key or keys used to locate the value.
 * @param values - The SearchRequest object containing filter state.
 * @returns The value extracted from the values object using the parsed key,
 *          or an empty string if the value is not found.
 */
export const defaultValuesKeyMapper = (
  pattern: string | string[] | undefined,
  values: SearchRequest
): string => {
  if (!pattern) return "";

  // Ensure keys is always an array and use the first value from it
  const patterns = Array.isArray(pattern) ? pattern : [pattern];
  const firstPattern = patterns[0];

  if (!firstPattern.includes(".")) {
    // Direct key lookup for simple keys
    return _.get(values, firstPattern, "");
  }

  const splitPattern = firstPattern.split(".");
  const field = splitPattern[0].toLowerCase();

  if (field === "advancedsearch") {
    // Special handling for "advancedSearch" type keys
    return _.get(values, "advancedSearch.keyword", "");
  }

  if (field === "advancedfilter") {
    // Special handling for "advancedFilter" type keys
    splitPattern.shift(); // remove advancedFilter part
    splitPattern.pop(); // Remove the operator part
    const field = splitPattern.join(".");
    const existingFilters = _.get(values, "advancedFilter.filters", []);
    const filterIndex = existingFilters.findIndex(e => e.field === field);

    return _.get(values, `advancedFilter.filters[${filterIndex}].value`, "");
  }

  // Fallback in case the key does not match known patterns
  return "";
};

/**
 * @description utility that handles updating SearchRequest in the case of AdvancedSearch pattern
 *
 * @param clonedRequest cloned version of SearchRequest used to fetch data
 * @param key the key used to map to the value that must be updated
 * @param value the value to be added to the SearchRequest
 */
export const handleAdvancedSearch = (
  clonedRequest: SearchRequest,
  key: string,
  value: string | string[] | { [key: string]: string | number | undefined } | undefined
) => {
  const fields: string[] = _.get(clonedRequest, "advancedSearch.fields", []);
  if (!fields.includes(key)) {
    _.set(clonedRequest, "advancedSearch.fields", [key, ...fields]);
  }
  _.set(clonedRequest, "advancedSearch.keyword", value);
};

/**
 * @description utility that handles updating SearchRequest in the case of AdvancedFilter pattern
 *
 * @param clonedRequest cloned version of SearchRequest used to fetch data
 * @param field specifies the field value of the element of the array of filters that must be updated
 * @param operator specifies the operator value used for the fetch operation
 * @param value the value to be added to the SearchRequest
 */
export const handleAdvancedFilter = (
  clonedRequest: SearchRequest,
  field: string,
  operator: string | undefined,
  value: string | string[] | { [key: string]: string | number | undefined } | undefined
) => {
  const filters = _.get(clonedRequest, "advancedFilter.filters", []);
  const filterIndex = filters.findIndex(e => e.field === field);

  // If the field of value to update exists inside filters
  if (filterIndex >= 0) {
    // If the value is safe (not undefined) we simply update the array of the value
    if (value) {
      _.set(clonedRequest, `advancedFilter.filters[${filterIndex}]`, {
        field,
        operator,
        value,
      });

      // If the value is not safe it means we need to completely REMOVE the array with the corresponding field name
    } else {
      filters.splice(filterIndex, 1);
      _.set(clonedRequest, "advancedFilter.filters", filters);
    }

    // If field is none existant and the value is safe, we simply either put the new filter array or concat to existing filters array
  } else if (value) {
    _.update(clonedRequest, "advancedFilter.filters", e =>
      e ? [...e, { field, operator, value }] : [{ field, operator, value }]
    );
  }
};

/**
 * @description This utility is used to validate if a given string pattern follows the set guidelines
 *
 * @param pattern the pattern value to be tested
 * @returns boolean value specifying whether the pattern is valid (true) or not (false)
 */
export const filterPatternValidation = (pattern: string): boolean => {
  const simplePattern = /^[a-zA-Z0-9]+$/;
  const advancedFilterPattern =
    /^advancedFilter\.([a-zA-Z0-9]+\.?)+\.(eq|neq|lt|lte|gt|gte|startswith|endswith|contains)$/;
  const advancedSearchPattern = /^advancedSearch\.([a-zA-Z0-9]+\.?)+/;

  return (
    simplePattern.test(pattern) ||
    advancedFilterPattern.test(pattern) ||
    advancedSearchPattern.test(pattern)
  );
};

/**
 * @description Groups an array of objects by a specified nested key.
 * @param {any[]} data - The array of data to be grouped.
 * @param {string} key - The key to group the data by. Supports nested keys using dot notation (e.g., "activityDomain.name").
 * @returns {Array<{ label: string, options: any[] }>} - An array of grouped data. Each group contains a label (group key) and an array of options (grouped items).
 */

export const groupDataByNestedKey = (data: any[], key: string) => {
  const map = data.reduce((map, item) => {
    const keys = key.split(".");
    const groupKey = keys.reduce((obj, k) => (obj ? obj[k] : undefined), item);

    const finalKey = groupKey || "Unknown";

    if (!map.has(finalKey)) {
      map.set(finalKey, []);
    }

    map.get(finalKey).push(item);

    return map;
  }, new Map());

  return Array.from(map, ([label, options]) => ({
    label,
    options,
  }));
};

/**
 * @description Moves the specified element (by its label) to the end of the array.
 * @param {any[]} data - The array of data containing elements with a 'label' property.
 * @param {string} element - The label of the element to move to the end of the list.
 * @returns {any[]} - A new array with the specified element moved to the end, maintaining the order of other elements.
 */

export const moveElementToEnd = (data: any[], element: string) => {
  return [
    ...data.filter(item => item.label !== element),
    ...data.filter(item => item.label === element),
  ];
};
