import { ApiEndpointBuilder } from "@/services/api/types.ts";

const deleteContractReminderEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: ({ id }) => ({
      url: `/contracts/enterprisecontracts/reminder/${id}`,
      method: "DELETE",
      body: { contractReminderId: id },
    }),
    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.contractId }],
  });
};

export default deleteContractReminderEndPoint;

type Request = {
  id: string;
  contractId: string;
};
