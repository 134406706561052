import { defineMessages } from "react-intl";

export const scope = "contracts.myTemplates";

export default defineMessages({
  name: {
    id: `${scope}.name`,
    defaultMessage: "Nom du template",
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: "Catégorie du template",
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: "Actions",
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: "Rechercher",
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: "Supprimer",
  },
  deleteTemplate: {
    id: `${scope}.deleteTemplate`,
    defaultMessage: "Voulez-vous vraiment supprimer ce template ?",
  },
  successDeletingTemplate: {
    id: `${scope}.successDeletingTemplate`,
    defaultMessage: "Le template a été supprimé avec succès.",
  },
  errorDeletingTemplate: {
    id: `${scope}.errorDeletingTemplate`,
    defaultMessage: "Une erreur est survenue lors de la suppression de ce template.",
  },
});
