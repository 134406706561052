import { useState } from "react";

const useToggleModal = (initialValue?: boolean) => {
  const [open, setOpen] = useState<boolean>(initialValue ?? false);
  return {
    isOpen: open,
    toggle: () => setOpen(prev => !prev),
    forceState: (state: boolean) => setOpen(state),
  };
};

export default useToggleModal;
