import { Box, styled } from "@mui/material";

export const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  backgroundColor: theme.palette.background.default,
  padding: "32px",
  borderRadius: "8px",
  display: "flex",
  gap: "2rem",
  flexDirection: "column",
  maxWidth: "30rem",
}));
