import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { DataResponse } from "@/types/reponse-data.ts";
import { AdvancedFilter } from "@/types/search-request.ts";
import { TemplateCategoryDetails, TemplateType } from "@/types/contracts";
import { TemplateSearchRequest } from "@/store/contracts/endpoints/templates-paragraphs/search-contract-template.ts";

const searchContractParagraphsEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<TemplateCategoryDetails>, TemplateSearchRequest>({
    query: request => {
      const defaultAdvancedFilter: AdvancedFilter[] = [
        {
          field: "templateType",
          operator: "eq",
          value: String(TemplateType.Paragraph),
        },
      ];

      const searchRequest = {
        ...request,
        advancedFilter: {
          logic: "and",
          filters: [...(request?.advancedFilter?.filters ?? []), ...defaultAdvancedFilter],
        },
      };

      return {
        url: "/contracts/templates/search",
        method: "POST",
        body: searchRequest, // Use the modified request
      };
    },
  });
};

export default searchContractParagraphsEndpoint;
