import { InputLabel, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

export const classesSelectCategories = {
  widthFilter: "width-filter",
  fullWidth: "full-width",
};

export const FormControlCategories = styled(FormControl)({
  ["&"]: {
    [".MuiInputLabel-shrink"]: {
      top: 0,
    },
  },
  [`&.${classesSelectCategories.widthFilter}`]: {
    minWidth: 180,
  },
  [`&.${classesSelectCategories.fullWidth}`]: {
    display: "flex",
    flexGrow: 1,
  },
});
export const InputLabelLongWord = styled(InputLabel)({
  ["&"]: {
    top: "-7px",
  },
});
