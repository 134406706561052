import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { ContractProjectDetails } from "@/types/contracts";
import { contractsApi } from "@/store/contracts/endpoints";

const addContractProjectEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: ({ contractCategory, ...request }) => ({
      url: `/contracts/projects`,
      method: "POST",
      body: { ...request, contractCategoryId: contractCategory.id },
    }),
    async onQueryStarted(args, { queryFulfilled, dispatch }) {
      try {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(
            contractsApi.util.updateQueryData("searchInfiniteContractProjects", {}, draftList => {
              draftList.data.unshift({
                ...args,
                id: data,
              } as ContractProjectDetails);
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
  });
};

export default addContractProjectEndpoint;

type Request = Omit<ContractProjectDetails, "id" | "enterpriseContracts" | "receiverEnterprise">;
