import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { contractsApi } from "../index.ts";

const deleteEnterpriseEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, { id: string }>({
    query: request => ({
      url: `/contracts/enterprisecontracts/${request.id}`,
      method: "DELETE",
      body: request,
    }),

    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      const patchResult = dispatch(
        contractsApi.util.updateQueryData("searchInfiniteContractEnterprises", {}, draftList => {
          return {
            ...draftList,
            data: [
              ...draftList?.data.filter(obj => {
                return obj.id != args?.id;
              }),
            ],
          };
        })
      );

      try {
        await queryFulfilled;
      } catch (err) {
        // Undo the cache update if an error occurs
        patchResult.undo();
      }
    },
  });
};

export default deleteEnterpriseEndpoint;
