import { Menu as MuiMenu, MenuItem as MuiMenuItem, styled } from "@mui/material";

export const StyledMenu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiList-root": {
    padding: 0,
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.background.default,
    border: `1px solid`,
    borderColor: theme.palette.border.main,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
  },
}));
 
export const StyledMenuItem = styled(MuiMenuItem, {
  shouldForwardProp: prop => !["isLastItem", "customColor"].includes(prop as string),
})<{ isLastItem: boolean; customColor?: string }>(({ theme, isLastItem, customColor }) => ({
  fontSize: "14px",
  gap: "10px",
  color: customColor,
  borderBottom: isLastItem ? "none" : `1px solid`,
  borderColor: theme.palette.border.main,
  "&:hover": {
    color: customColor || theme.palette.primary.main,
  },
}));
