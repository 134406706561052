import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { File } from "@/types/contracts";

const updateContractDocumentEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/documents`,
      method: "PUT",
      body: request,
    }),

    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.contractId }],
  });
};

export default updateContractDocumentEndPoint;

export type Request = Partial<Omit<File, "assetType" | "data" | "extension">> & {
  contractId: string;
};
