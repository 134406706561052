import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { ContractReminder } from "@/types/contracts";

const updateContractReminderEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: ({ contractId, ...request }) => ({
      url: `/contracts/enterprisecontracts/reminder`,
      method: "PUT",
      body: request,
    }),
    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.contractId }],
  });
};

export default updateContractReminderEndPoint;

type Request = ContractReminder & { contractReminderId: string; contractId: string };
