import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { DataResponse } from "@/types/reponse-data.ts";
import { SearchRequest } from "@/types/search-request.ts";
import { FileTag } from "@/types/contracts";

const searchContractFilesTagsEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<FileTag>, SearchRequest>({
    query: request => ({
      url: "/contracts/filestags/search",
      method: "POST",
      body: request,
    }),
  });
};

export default searchContractFilesTagsEndpoint;
