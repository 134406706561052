import { Enterprise } from "@/types/store/profile.ts";

export type Category = {
  name: string;
  description: string;
};

export type Template = {
  name: string;
  description: string;
  content: string;
  ressourceType: ResourceType;
  templateType: TemplateType;
};

export type TemplateDetails = Template & {
  id: string;
  contractCategoryId: string;
};

export type ContractCategory = Category & {
  id: string;
};

export type TemplateCategoryDetails = Template & {
  id?: string;
  contractCategory: ContractCategory;
};

export enum ResourceType {
  Enterprise,
  //Expert,
}

export enum TemplateType {
  Paragraph,
  Template,
  // Footer,
}

export type ContractProject = {
  name: string;
  description: string;
  contractCategoryId: string;
};

export type ContractProjectDetails = Omit<ContractProject, "contractCategoryId"> & {
  id: string;
  description: string;
  contractCategory: ContractCategory;
  enterpriseContracts: [];
  receiverEnterprise: [];
};

export interface ContractEnterprise {
  name: string;
  description: string;
  contractCategoryId: string;
  senderEnterpriseId: string;
  receiverEnterpriseId: string;
  contractProjectId: string;
  contractProject: Omit<ContractProject, "contractCategoryId">;
  expirationDate: string;
  startDate: string;
  content: string;
  price: number;
  currency: Currency;
  paymentMethod: PaymentMethod;
  paymentDate: string;
}

export type ContractEnterpriseDetails = Omit<
  ContractEnterprise,
  "senderEnterpriseId" | "receiverEnterpriseId"
> & {
  id: string;
  createdOn: string;
  contentLastModifiedOn: string;
  senderEnterprise: Enterprise;
  receiverEnterprise: Enterprise;
  contractCategory: ContractCategory;
  contractProject: ContractProjectDetails;
  contractDocuments: ContractDocument[];
  contractReminders: ContractReminderDetails[];
  userContractRoles: ContractUserRole[];
};

export type ContractUserRole = {
  enterpriseEmployee: EnterpriseEmployee;
  createdOn: string;
  role: Permission;
};

export type ContractDocument = {
  id: string;
  name: string;
  type: string;
  azureBlobUri: string;
  lastModifiedOn: string;
  filesTags: FileTag[];
};

export type FileTag = {
  name: string;
  id: string;
  description: string;
};

export type DocumentDetails = {
  contractId: string;
  file: File;
};

export type ContractReminderDetails = {
  id: string;
  name: string;
  date: string;
  reason: ReminderReason;
  relativeDate: ReminderRelatedDate;
  relativeDateDuration: number; // Time difference between `relativeDate` and the reminder
  isDurationInDays: boolean; // true = duration in days, false = duration in months
  isReminderBeforeRelativeDate: boolean; // true = before, false = after the `relativeDate`
  enterpriseEmployees: EnterpriseEmployee[];
};

export type EnterpriseEmployee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  jobTitle: string;
};

export type UserRole = {
  enterpriseContractId: string;
  employeeId: string;
  role: Permission;
};

export enum Permission {
  Member = 0,
  Admin = 1,
}

export type File = {
  id?: string;
  name: string;
  extension: string;
  data: string;
  assetType: number;
  filesTagIds: string[];
  description?: string;
};

export type ContractReminder = Omit<ContractReminderDetails, "id" | "enterpriseEmployees"> & {
  usersToRemind: string[];
};

export enum ReminderRelatedDate {
  None,
  StartDate,
  ExpirationDate,
}

export enum ReminderReason {
  Expiration,
  Renewal,
  Payment,
  Other,
}

export enum PaymentMethod {
  Check,
  Cash,
  BankTransfer,
}

export enum Currency {
  Dollar,
  Euro,
}
