import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { DataResponse } from "@/types/reponse-data.ts";
import { AdvancedFilter } from "@/types/search-request.ts";
import { TemplateCategoryDetails } from "@/types/contracts";

const searchContractTemplatesEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<TemplateCategoryDetails>, TemplateSearchRequest>({
    query: request => ({
      url: "/contracts/templates/search",
      method: "POST",
      body: request,
    }),
  });
};

export default searchContractTemplatesEndpoint;

export interface TemplateSearchRequest {
  advancedSearch?: {
    fields: string[];
    keyword: string;
  };
  keyword?: string;
  advancedFilter?: AdvancedFilter;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string[];
  onlyMine?: boolean;
  onlyRoot?: boolean;
}
