import { ApiEndpointBuilder } from "@/services/api/types.ts";
import { UserRole } from "@/types/contracts";

const updateContractUserRoleEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/enterprisecontracts/userrole`,
      method: "PUT",
      body: request,
    }),

    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.enterpriseContractId }],
  });
};

export default updateContractUserRoleEndPoint;

export type Request = UserRole;
