import { Box, Chip, chipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomChip = styled(Chip)(({ theme }) => ({
  ["&"]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginX: "2px",
    [`& .${chipClasses.deleteIcon}`]: {
      color: theme.palette.primary.contrastText,
      ":hover": {
        color: theme.palette.primary.contrastText,
        opacity: 0.8,
      },
    },
  },
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  lineHeight: "1.25rem",
  display: "flex",
  maxWidth: "85%",
  overflowX: "auto",
  minHeight: "35px",
  "&::-webkit-scrollbar-track": {
    background: theme.palette.customLightGrey.light,
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.light,
    cursor: "initial",
  },
}));
