import FilterComponent, {
  FilterComponentProps,
  onSearchHandlerType,
  Types,
} from "@/components/filter-component";
import NoData from "@/components/no-data";
import useDimensions from "@/hooks/useDimensions";
import { DataResponse } from "@/types/reponse-data";
import { SearchRequest } from "@/types/search-request";
import { Box } from "@mui/material";
import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import _ from "lodash";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_RowData,
  MRT_SortingState,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_RowSelectionState,
  MRT_TablePaperProps,
} from "material-react-table";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  defaultValuesKeyMapper,
  filterPatternValidation,
  handleAdvancedFilter,
  handleAdvancedSearch,
} from "../filter-page-wrapper/utilities";
import messages from "../filter-page-wrapper/messages";
import { useIntl } from "react-intl";
import useDirection from "@/hooks/useDirection";
import { ClearButton, DataTableContainer } from "./styles";

interface RowSelection {
  [key: string]: boolean;
}

// Define the type for the columns

type SortableOrFilterAble =
  | {
      sortable: true;
      pattern?: string;
      filterable?: never;
      filterPattern?: never;
    }
  | {
      sortable?: never;
      pattern?: never;
      filterable: true;
      filterPattern: string;
    }
  | {
      sortable: true;
      pattern?: string;
      filterable: true;
      filterPattern: string;
    }
  | {
      sortable?: never;
      pattern?: never;
      filterable?: never;
      filterPattern?: never;
    };

// Define the props for the DataTable component

type DataTableProps<TData extends MRT_RowData> = {
  columns: (MRT_ColumnDef<TData> & SortableOrFilterAble)[];
  lazyQuery: UseLazyQuery<QueryDefinition<any, any, any, DataResponse<TData>, any>>;
  configProps: {
    useDimensionsMargin?: number;
    defaultQueryValues?: { [key: string]: unknown };
    MRT_tableOptions?: Partial<MRT_TableOptions<TData>>;
    muiTablePaperProps?: Partial<MRT_TablePaperProps<TData>>;
  };
  configRowSelection?: {
    selectedColumnId: string;
    enableRowSelection: boolean;
    defaultRowSelection?: RowSelection;
    onRowSelectionChange?: (selection: MRT_RowSelectionState) => void;
    hideToolbarRowSelectionBanner?: boolean;
  };
  configFilters?: {
    showColumnFilters: boolean;
    showActionsColumn?: boolean;
  };
  enableTopToolbar?: boolean;
  filters?: FilterComponentProps[];
  clearFilterOption?: boolean;
  headerRightSideActions?: JSX.Element;
};

// Define the DataTable component
const DataTable = <TData extends MRT_RowData>({
  columns: configColumns,
  lazyQuery,
  configProps = { useDimensionsMargin: 0, defaultQueryValues: {}, MRT_tableOptions: {} },
  configRowSelection,
  configFilters,
  enableTopToolbar = false,
  filters,
  clearFilterOption,
  headerRightSideActions,
}: DataTableProps<TData>) => {
  // State management
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(
    configRowSelection?.defaultRowSelection ?? {}
  );
  const { formatMessage: __ } = useIntl();
  const currentDirection = useDirection();

  const { safeHeight } = useDimensions({ margin: configProps.useDimensionsMargin });
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [triggerFetch, { data: fetchedData, isFetching, isLoading, isError }] = lazyQuery();

  // Search request management
  const searchRequestRef = useRef<SearchRequest>({
    pageNumber: 0,
    pageSize: 20,
    keyword: undefined,
    advancedFilter: undefined,
    orderBy: undefined,
    ...configProps.defaultQueryValues,
  });

  // Memoized data and no data state
  const data = useMemo(() => fetchedData?.data ?? [], [fetchedData?.data]);
  const noData = !(data?.length > 0 || isFetching || isLoading);

  // Memoized columns with added styling

  const columns = useMemo(() => {
    return configColumns?.map(({ Cell, sortable, filterable, pattern, ...rest }) => ({
      ...rest,
      Cell: ({ ...args }) => (
        <Box component="div" sx={{ ["& *,&"]: { textTransform: "uppercase" } }}>
          {Cell ? Cell(args) : args.renderedCellValue}
        </Box>
      ),
      enableSorting: sortable ?? false,
      enableColumnFilter: filterable ?? false,
    })) as MRT_ColumnDef<TData>[];
  }, [configColumns]);

  // Fetch handler
  const fetchHandler = useCallback(
    (preferCacheValue?: boolean) => {
      const { advancedFilter, advancedSearch } = searchRequestRef.current;
      triggerFetch(
        {
          ...searchRequestRef.current,
          advancedFilter: advancedFilter?.filters?.length ? advancedFilter : undefined,
          advancedSearch: advancedSearch?.keyword ? advancedSearch : undefined,
        },
        preferCacheValue
      );
    },
    [triggerFetch]
  );

  // Backend-powered sorting
  const [controlledFiltering, setControlledFiltering] = useState("");
  const [controlledColumnFiltering, setControlledColumnFiltering] =
    useState<MRT_ColumnFiltersState>([]);

  // Search handler
  const onSearchHandler: onSearchHandlerType = useCallback(
    (
      value: string | string[] | { [key: string]: string | number | undefined } | undefined,
      key: string | string[],
      options?: { skipFetch?: boolean }
    ) => {
      const { skipFetch = false } = options ?? {};

      // Ensure key is always an array
      const keys = Array.isArray(key) ? key : [key];

      // Deep cloning the object
      const clonedSearchRequest: SearchRequest = structuredClone(searchRequestRef.current);

      // Clean value, replaces empty strings with `undefined`
      const cleanedValue =
        (!Array.isArray(value) && typeof value === "object") || (value && value.length > 0)
          ? value
          : undefined;

      // Iterate through each key and apply value update logic
      keys.forEach(singleKey => {
        if (singleKey.includes(".")) {
          const [type, ...rest] = singleKey.split(".");

          // advancedSearch pattern case
          if (type.toLowerCase() === "advancedsearch") {
            const field = rest.join(".");
            handleAdvancedSearch(clonedSearchRequest, field, cleanedValue);

            // advancedFilter pattern case
          } else if (type.toLowerCase() === "advancedfilter") {
            const operator = rest.pop();
            const field = rest.join(".");
            handleAdvancedFilter(clonedSearchRequest, field, operator, cleanedValue);
          }

          // simple pattern case
        } else {
          _.set(clonedSearchRequest, singleKey, cleanedValue);
        }
      });

      // Update search request and trigger fetch if necessary
      searchRequestRef.current = { ...clonedSearchRequest, pageNumber: 0 };

      if (!skipFetch) {
        fetchHandler();
      }
    },
    [fetchHandler]
  );

  // Backend-powered sorting effects
  useEffect(() => {
    const tmp = structuredClone(searchRequestRef.current);
    controlledColumnFiltering.map(filter =>
      onSearchHandler(
        filter.value as string,
        (columns.find(column => column.accessorKey === filter.id) as any)?.filterPattern ??
          filter.id
      )
    );
    searchRequestRef.current = { ...tmp, pageNumber: 0 };
    if (controlledColumnFiltering.length > 0) {
      searchRequestRef.current = {
        ...searchRequestRef.current,
        advancedFilter: { ...searchRequestRef.current.advancedFilter, logic: "and" },
      };
    } else {
      searchRequestRef.current = {
        pageNumber: 0,
        pageSize: 20,
        keyword: undefined,
        advancedFilter: undefined,
        orderBy: undefined,
        ...configProps.defaultQueryValues,
      };
    }
    fetchHandler();
  }, [controlledFiltering, controlledColumnFiltering]);

  // Backend-powered sorting
  const [controlledSorting, setControlledSorting] = useState<MRT_SortingState>([]);

  const onSortingHandler = useCallback(() => {
    searchRequestRef.current = {
      ...searchRequestRef.current,
      ...(controlledSorting?.length > 0 && {
        orderBy: controlledSorting.map(({ id, desc }) => `${id} ${desc ? "desc" : "asc"}`),
      }),
    };

    fetchHandler(true);
  }, [controlledSorting]);

  useEffect(() => {
    onSortingHandler();
  }, [controlledSorting]);

  // Infinite scrolling
  const nextPageCallback = useCallback(() => {
    if (fetchedData?.hasNextPage) {
      const newPageNumber = (searchRequestRef.current.pageNumber || 0) + 1;
      searchRequestRef.current = {
        ...searchRequestRef.current,
        pageNumber: newPageNumber,
      };

      fetchHandler();
    }
  }, [fetchedData?.hasNextPage]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 50 &&
          !isFetching &&
          fetchedData?.hasNextPage
        ) {
          nextPageCallback();
        }
      }
    },
    [isFetching, fetchedData?.hasNextPage, nextPageCallback]
  );

  // Initial fetch
  useEffect(() => {
    fetchHandler(true);
  }, []);

  // Clear filter effect
  useEffect(() => {
    if (!clearFilter) return;
    searchRequestRef.current = {
      pageNumber: 0,
      pageSize: 20,
      keyword: undefined,
      ...configProps.defaultQueryValues,
    };
    fetchHandler();
    setClearFilter(false);
  }, [clearFilter]);

  // Row selection change handler
  const handleSelectionChange = useCallback(
    (newRowSelection: any) => {
      setRowSelection(prev => {
        const newValue = newRowSelection(prev);
        const removedRow: RowSelection = {};
        if (Object.keys(prev).length > Object.keys(newValue).length) {
          for (const key in prev) {
            if (!(key in newValue)) {
              removedRow[key] = false;
            }
          }
        }
        const rowSelection = { ...newValue, ...removedRow };
        if (configRowSelection?.onRowSelectionChange)
          configRowSelection.onRowSelectionChange(rowSelection);
        return newValue;
      });
    },
    [rowSelection]
  );

  // Material react table config
  const table = useMaterialReactTable({
    columns,
    data: data,
    state: {
      showAlertBanner: isError,
      showProgressBars: isFetching,
      isLoading: isLoading || (!data?.length && isFetching),
      sorting: controlledSorting,
      globalFilter: controlledFiltering,
      columnFilters: controlledColumnFiltering,
      rowSelection: rowSelection,
    },
    enableTopToolbar: enableTopToolbar,
    manualSorting: true,
    onSortingChange: setControlledSorting,
    manualFiltering: true,
    onGlobalFilterChange: setControlledFiltering,
    enableColumnFilters: true,
    onColumnFiltersChange: setControlledColumnFiltering,
    enableColumnOrdering: true, //enable a feature for all columns
    enableColumnFilterModes: false,
    enableGlobalFilter: false,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableBottomToolbar: false,
    enablePagination: false,
    enableRowVirtualization: true,
    enableRowSelection: configRowSelection?.enableRowSelection ?? false,
    onRowSelectionChange: handleSelectionChange,
    getRowId: row => row[configRowSelection?.selectedColumnId ?? "id"],
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    initialState: {
      showColumnFilters: configFilters?.showColumnFilters,
      columnVisibility: {
        actions: configFilters?.showActionsColumn ? configFilters?.showActionsColumn : true,
      },
      density: "compact",
    },
    defaultColumn: {
      // minSize: 100,
    },
    layoutMode: "grid",
    positionToolbarAlertBanner: configRowSelection?.hideToolbarRowSelectionBanner ? "none" : "top",
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: {
        maxHeight: safeHeight,
        height: "fit-content",
        minHeight: noData ? "250px" : 0,
      }, //give the table a max height
      onScroll: event => {
        fetchMoreOnBottomReached(event.target as HTMLDivElement);
      }, //add an event listener to the table container element
    },
    muiTablePaperProps: {
      ...configProps?.muiTablePaperProps,
      sx: { boxShadow: "none", ...configProps?.muiTablePaperProps?.sx },
    },

    muiTableBodyCellProps: {
      sx: {
        direction: currentDirection,
      },
    },

    muiTableHeadCellProps: {
      sx: {
        "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
          left: "unset",
          right: "0px",
        },
      },
    },
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          height: "auto",
        }}
      ></Box>
    ),

    columnFilterDisplayMode: "subheader",

    ...configProps.MRT_tableOptions,
  });

  // Render filters
  const renderFilters =
    filters &&
    filters?.map((filter, index) => {
      // Validate the format of the pattern, and in case of wrong pattern format, throw an error
      const patterns = Array.isArray(filter.pattern) ? filter.pattern : [filter.pattern];
      patterns.forEach(pattern => {
        if (!filterPatternValidation(pattern)) {
          throw Error(`Invalid pattern provided: ${pattern}
          Patterns should follow one of these formats:
          - simple: [key] eg: "keyword"
          - advancedFilter: advancedFilter.[field].[operator] eg: "advancedFilter.description.contains", "advancedFilter.type.id.eq"
          - advancedSearch: advancedSearch.[field] eg: "advancedSearch.firstName"`);
        }
      });

      if (filter.type === Types.CUSTOM && filter.render) {
        return (
          <Fragment key={index}>
            {filter.render(
              onSearchHandler,
              defaultValuesKeyMapper(filter.pattern, { ...searchRequestRef.current }),
              clearFilter
            )}
          </Fragment>
        );
      } else {
        return (
          <Box key={filter.pattern.toString()} sx={filter.style}>
            <FilterComponent
              type={filter.type}
              label={filter.label}
              optionLabel={filter.optionLabel}
              pattern={filter.pattern}
              text={filter.text}
              secondaryText={filter.secondaryText}
              onClickEvent={filter.onClickEvent}
              options={filter.options}
              onSearchEvent={onSearchHandler}
              defaultValue={defaultValuesKeyMapper(filter.pattern, {
                ...searchRequestRef.current,
              })}
              disabled={isFetching}
            />
          </Box>
        );
      }
    });

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          marginBottom: 0.8,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            marginBottom: 2,
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            width: "100%",
          }}
        >
          {renderFilters}
          {clearFilterOption && (
            <ClearButton onClick={() => setClearFilter(true)} size="small">
              {__(messages.clearFilter)}
            </ClearButton>
          )}
        </Box>
        {headerRightSideActions && (
          <Box
            sx={{
              width: "250px",
              marginBottom: 2,
              gap: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {headerRightSideActions}
          </Box>
        )}
      </Box>

      {noData && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -20%)",
            zIndex: 1
          }}
        >
          <NoData />
        </Box>
      )}

      <DataTableContainer
        sx={{
          minHeight: noData ? "300px" : 0,
          backgroundColor: "transparent",
        }}
      >
        <MaterialReactTable table={table} />
      </DataTableContainer>
    </Box>
  );
};

export default DataTable;
