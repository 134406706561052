import { Box, Button, styled } from "@mui/material";

export const DataTableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  opacity: 1,
  ["tr"] :{
    backgroundColor: theme.palette.background.default,
  },
  ["td"] :{
    backgroundColor: theme.palette.background.default,
  },
  ["& .MuiPaper-root"]: {
    backgroundColor: theme.palette.background.default,
  }
}));


export const ClearButton = styled(Button)(({ theme }) => ({
  textDecoration: "underline",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
}));
