import {
  SxProps,
  TextField,
  Theme,
  Autocomplete as MAutocomplete,
  Typography,
} from "@mui/material";
import { CSSProperties, FC, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import ButtonVaried from "@/components/button";
import BoxHeader from "@/components/box-header/BoxHeader";
import useDebounce from "@/hooks/useDebounce";
import Autocomplete from "@/components/auto-complete";
import DraweredSelect from "@components/filter-component/components/DraweredSelect.tsx";
import SelectOptionFilter from "../select-option/select-option-filter";
import Select, { CSSObjectWithLabel } from "react-select";
import { useTheme } from "@mui/material/styles";

export enum Types {
  TEXT_FIELD,
  SELECT_OPTION,
  SELECT_OPTION_DRAWER,
  SINGLE_SELECT_OPTION,
  MULTI_SELECT_OPTION,
  BUTTON,
  TITLE_BOX,
  AUTO_COMPLETE,
  AUTO_COMPLETE_DRAWER,
  MAUTO_COMPLETE,
  CUSTOM,
}

interface GroupedOption {
  label: string;
  options: any[];
}

const FilterComponent: FC<FilterComponentProps> = ({
  type,
  text,
  label,
  secondaryText,
  options,
  disabled,
  onSearchEvent,
  onClickEvent,
  pattern = "",
  defaultValue = "",
  optionLabel,
  isOptionsGrouped,
  name,
  style = {},
}) => {
  const theme = useTheme();
  const [value, setValue] = useState<string | string[]>(defaultValue);
  const skipFetch = useRef<boolean>(true);
  const debouncedSearch = useDebounce(value);

  const [inputIsReadyToFocus, setInputIsReadyToFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!disabled && inputIsReadyToFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  useEffect(() => {
    if (defaultValue.toString() !== value.toString()) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (type !== Types.TEXT_FIELD && !skipFetch.current && onSearchEvent) {
      onSearchEvent(value, pattern);
    }
  }, [value]);

  useEffect(() => {
    if (type === Types.TEXT_FIELD && !skipFetch.current && onSearchEvent) {
      onSearchEvent(value, pattern);
    }
  }, [debouncedSearch]);

  const handleValueChange = useCallback((value: string | string[]) => {
    skipFetch.current = false;
    setValue(value);
  }, []);

  const formatGroupLabel = (data: GroupedOption) => (
    <Typography fontSize={"12px"} color={theme.palette.primary.main} style={{ fontWeight: "bold" }}>
      {data.label}
    </Typography>
  );

  const conditionalRendering = (type: Types) => {
    switch (type) {
      case Types.TEXT_FIELD:
        return (
          <TextField
            label={label}
            variant="outlined"
            size="small"
            className="Search"
            onChange={e => {
              handleValueChange(e.target.value);
              !inputIsReadyToFocus && setInputIsReadyToFocus(true);
            }}
            value={value}
            onBlur={() => setInputIsReadyToFocus(false)}
            disabled={disabled}
            fullWidth
            inputRef={inputRef}
            sx={{
              backgroundColor: theme.palette.background.default,
              "& .MuiOutlinedInput-root": {
                borderRadius: 0,
              },
            }}
          />
        );
      case Types.SELECT_OPTION:
        return (
          <SelectOptionFilter
            label={label}
            optionLabel={optionLabel}
            value={value.toString()}
            onChange={e => handleValueChange(e)}
            options={options || []}
            disabled={disabled}
            fullWidth
            sx={style as SxProps}
            disableNoneValue={false}
          />
        );
      case Types.SELECT_OPTION_DRAWER:
        return (
          <DraweredSelect
            variant="single"
            options={
              options
                ? options?.map(data => {
                    return {
                      label: data[optionLabel || "label"],
                      value: data.id,
                    };
                  })
                : []
            }
            title={label}
            defaultValue={value.toString()}
            onChange={e => {
              handleValueChange(e);
            }}
          />
        );
      case Types.SINGLE_SELECT_OPTION:
        return (
          <Select
            id={pattern.toString()}
            options={options || []}
            value={
              isOptionsGrouped
                ? (options || [])
                    .flatMap(group => group.options)
                    .filter(option => option && option.id.toString() === value)
                : (options || []).filter(option => option.id.toString() === value)
            }
            onChange={selectedOption => {
              handleValueChange(selectedOption ? selectedOption.id.toString() : "");
            }}
            isDisabled={disabled || false}
            placeholder={label}
            name={name || ""}
            getOptionLabel={option => option[optionLabel || "label"]}
            getOptionValue={option => option.id}
            formatGroupLabel={isOptionsGrouped ? formatGroupLabel : undefined}
            styles={{
              control: (provided, state) => ({
                ...provided,
                fontWeight: 400,
                borderColor: state.isFocused ? theme.palette.primary.main : provided.borderColor,
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${theme.palette.primary.main}`
                  : provided.boxShadow,
                color: theme.palette.neutral.main,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? theme.palette.primary.main
                  : state.isFocused
                  ? theme.palette.customGrey.light
                  : "transparent",
                cursor: "pointer",
              }),
            }}
          />
        );
      case Types.MULTI_SELECT_OPTION:
        return (
          <Select<any, true, GroupedOption>
            id={pattern.toString()}
            isMulti
            options={options || []}
            value={
              isOptionsGrouped
                ? (options || [])
                    .flatMap(group => group.options)
                    .filter(option => value.includes(option.id))
                : (options || []).filter(option => value.includes(option.id))
            }
            onChange={selectedOptions => {
              const selected: string[] = selectedOptions.map(option => option.id);
              handleValueChange(selected);
            }}
            isDisabled={disabled || false}
            placeholder={label}
            name={name || ""}
            getOptionLabel={option => option[optionLabel || "label"]}
            getOptionValue={option => option.id}
            formatGroupLabel={isOptionsGrouped ? formatGroupLabel : undefined}
            styles={{
              control: (provided, state) => ({
                ...provided,
                ...(style as CSSObjectWithLabel),
                fontWeight: 400,
                borderColor: state.isFocused ? theme.palette.primary.main : provided.borderColor,
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${theme.palette.primary.main}`
                  : provided.boxShadow,
                color: theme.palette.neutral.main,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? theme.palette.primary.main
                  : state.isFocused
                  ? theme.palette.customGrey.light
                  : "transparent",
                cursor: "pointer",
              }),
              groupHeading: base => ({
                ...base,
                fontSize: "12px",
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }),
              valueContainer: base => ({
                ...base,
                maxHeight: 36,
                overflowX: "auto",
                overflowY: "hidden",
                flexWrap: "nowrap",
                "&::-webkit-scrollbar": {
                  height: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background:
                    theme.palette.mode === "light"
                      ? theme.palette.customLightGrey.light
                      : theme.palette.gray.contrastText,
                  marginLeft: 8,
                },
                "&::-webkit-scrollbar-thumb": {
                  paddingLeft: "30px",
                  marginLeft: "30px",
                  borderRadius: "1px",
                },
                "& > div": {
                  flex: "0 0 auto",
                },
                "& > div[class*='-Input']": {
                  position: "relative",
                  transform: "none",
                },
              }),
              multiValue: base => ({
                ...base,
                flex: "0 0 auto",
                margin: "2px",
                lineHeight: "16px",
              }),
              container: base => ({
                ...base,
                "& .select__value-container::-webkit-scrollbar": {
                  height: "2px",
                },
                "& .select__value-container::-webkit-scrollbar-track": {
                  background:
                    theme.palette.mode === "light"
                      ? theme.palette.customLightGrey.light
                      : theme.palette.gray.contrastText,
                },
                "& .select__value-container::-webkit-scrollbar-thumb": {
                  borderRadius: "1px",
                  background:
                    theme.palette.mode === "light"
                      ? theme.palette.secondary.main
                      : theme.palette.customLightGrey.light,
                },
              }),
            }}
          />
        );
      case Types.BUTTON:
        return <ButtonVaried variantType="contained" textContent={text} onAction={onClickEvent} />;
      case Types.TITLE_BOX:
        return <BoxHeader textTitle={text} textSubTitle={secondaryText} variant="h6" />;
      case Types.AUTO_COMPLETE:
        return (
          <Autocomplete
            id={pattern.toString()}
            data={options || []}
            label={label}
            multi={true}
            selectedValue={(options || []).filter(data => value.includes(data.id))}
            setSelectedValue={options => {
              handleValueChange(options.map(e => e.id.toString()));
            }}
            disabled={disabled || false}
            placeholder={label}
            name={name || ""}
            optionLabel={optionLabel || "label"}
            submitCount={0}
            errors={{}}
            InputLabelStyle={{
              fontWeight: 400,
            }}
          />
        );
      case Types.AUTO_COMPLETE_DRAWER:
        return (
          <DraweredSelect
            variant="multi"
            options={
              options
                ? options?.map(data => {
                    return {
                      label: data[optionLabel || "label"],
                      value: data.id,
                    };
                  })
                : []
            }
            title={label}
            defaultValue={typeof value !== "string" ? value : []}
            onChange={values => {
              handleValueChange(values);
            }}
          />
        );
      case Types.MAUTO_COMPLETE:
        return (
          <MAutocomplete
            disablePortal
            id={pattern.toString()}
            options={options || []}
            getOptionLabel={option => `${option.label}`}
            onChange={(_, newValue, reason) => {
              /**
               * TODO: remove the wrapping of the value inside an array
               * * In future iterations: Any component of this type that needs wrapping its value inside an array should be of CUSTOM type
               * ! removing the wrapping now breaks PR #1078
               */
              reason === "clear" ? handleValueChange("") : handleValueChange([newValue.id]);
            }}
            renderInput={params => (
              <TextField {...params} placeholder={label} label={label} size="small" />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography fontSize={"14px"} fontWeight={500} variant="body1">
                  {`${option.label}`}
                </Typography>
              </li>
            )}
          />
        );
      default:
        break;
    }
  };

  return <>{conditionalRendering(type)}</>;
};

export type onSearchHandlerType = (
  value: string | string[] | { [key: string]: string | number | undefined } | undefined,
  key: string | string[],
  options?: { skipFetch?: boolean }
) => void;

export type FilterComponentProps = {
  type: Types;
  label: string;
  pattern: string | string[];
  onSearchEvent?: onSearchHandlerType;
  defaultValue?: string | string[];
  disabled?: boolean;
  options?: any[];
  text?: string;
  secondaryText?: string;
  onClickEvent?: () => void;
  optionLabel?: string;
  isOptionsGrouped?: boolean;
  name?: string;
  style?: CSSProperties | SxProps<Theme> | undefined;
  render?: (
    onSearchHandler: onSearchHandlerType,
    defaultValue: string | string[] | { [key: string]: string | number | undefined },
    clearFilter?: boolean
  ) => ReactElement;
};

export default FilterComponent;
