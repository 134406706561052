import { ApiEndpointBuilder } from "@/services/api/types.ts";

const deleteContractDocumentsEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: request => ({
      url: `/contracts/documents`,
      method: "DELETE",
      body: request,
    }),
    invalidatesTags: (_r, _e, arg) => [{ type: "contract", id: arg.contractId }],
  });
};

export default deleteContractDocumentsEndPoint;

type Request = {
  documentIds: string[];
  contractId: string;
};
