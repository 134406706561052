import { defineMessages } from "react-intl";

const globalScope = "filterPageWrapper";
const scope = `${globalScope}.filterComponents`;

const messages = defineMessages({
  filter: {
    id: `${scope}.filter`,
    defaultMessage: "Filtre ",
  },
  shouldSelect: {
    id: `${scope}.shouldSelect`,
    defaultMessage: "Veuillez selectionner ",
  },
  singleSelect: {
    id: `${scope}.singleSelect`,
    defaultMessage: "un",
  },
  multiSelect: {
    id: `${scope}.multiSelect`,
    defaultMessage: "un ou plus",
  },
  nextElement: {
    id: `${scope}.nextElement`,
    defaultMessage: " des élements suivants",
  },
  applyFilter: {
    id: `${scope}.applyFilter`,
    defaultMessage: "Applique les filtres",
  },
});

export default messages;
