import { ButtonProps, Modal, Typography } from "@mui/material";
import { FormEvent, useCallback, useState } from "react";
import InputWrapper from "../input-wrapper";
import { useIntl } from "react-intl";
import messages from "./messages";
import { ModalBox } from "./styles";

type ConfirmationModalProps = {
  open: boolean;
  toggle: () => void;
  action: () => Promise<void>;
  message: string | JSX.Element;
} & (
  | { defaultMode: true; additionalButtons?: never }
  | {
      defaultMode?: never;
      additionalButtons?: {
        text: string;
        action: () => Promise<void>;
        state: "secondary" | "primary";
        extraProps: ButtonProps;
        isLoading: boolean;
      }[];
    }
);
const ConfirmationModal = ({
  open,
  toggle,
  action,
  message,
  additionalButtons,
}: ConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formatMessage: __ } = useIntl();
  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsLoading(true);
      try {
        await action();
        toggle();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [action, toggle]
  );

  const onReset = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      toggle();
    },
    [toggle]
  );
  return (
    <Modal
      open={open}
      onClose={() => {
        !isLoading && toggle();
      }}
    >
      <ModalBox>
        <Typography variant="h5">{message}</Typography>
        <form
          onSubmit={onSubmit}
          onReset={onReset}
          style={{ display: "flex", alignItems: "center" }}
        >
          <InputWrapper
            errors={{}}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            buttons={[
              {
                textButton: __(messages.cancel),
                buttonProps: { type: "reset" },
                buttonState: "secondary",
                isLoading: false,
              },
              additionalButtons?.map(button => ({
                textButton: button.text,
                buttonProps: {
                  type: "submit",
                  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    button.action();
                  },
                  ...button.extraProps,
                },
                buttonState: button.state,
                isLoading: button.isLoading,
              })),
              {
                textButton: __(messages.confirm),
                buttonProps: { type: "submit", variant: "contained" },
                buttonState: "primary",
                isLoading: isLoading,
              },
            ]
              .filter(Boolean)
              .flat()}
          />
        </form>
      </ModalBox>
    </Modal>
  );
};

export default ConfirmationModal;
